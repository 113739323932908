<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-11.mp3"),
      },
      timeStampList: [8.0, 12.0, 15.8, 23.8, 28.1, 32.0, 38.5],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Gēge! Wǒ shì yí ge gēge.",
            chineseWords: "哥哥！我是一个哥哥。",
          },
          {
            id: 2,
            spelling: "Mèimei! Wǒ yǒu yí ge mèimei.",
            chineseWords: "妹妹！我有一个妹妹。",
          },
          {
            id: 3,
            spelling: "Lā... lā... Wǒ ài mèimei.",
            chineseWords: "啦……啦……我爱妹妹。",
          },
          {
            id: 4,
            spelling: "Mèimei! Wǒ shì yí ge mèimei.",
            chineseWords: "妹妹！我是一个妹妹。",
          },
          {
            id: 5,
            spelling: "Gēge! Wǒ yǒu yí ge gēge.",
            chineseWords: "哥哥！我有一个哥哥。",
          },
          {
            id: 6,
            spelling: "Lā... lā ... Wǒ ài gēge.",
            chineseWords: "啦……啦……我爱哥哥。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
